$pagination-prefix-cls: 'zy-pagination';

.#{$pagination-prefix-cls} {
    font-size: 14px;
    font-weight: 400;

    display: flex;

    color: #212121;

    justify-content: space-between;
    flex-wrap: wrap;
    > div {
        margin-top: 3px;
        margin-bottom: 3px;
    }
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        list-style: none;
    }

    &-total {
        display: flex;

        align-items: center;
        &-title {
            color: #9ca5c9;
        }
        &-num {
            color: #3cd0ac;
        }
    }
    &-main {
        display: flex;

        align-items: center;
        flex-wrap: wrap;
    }
    &-item,
    &-prev,
    &-next,
    &-jump-prev,
    &-jump-next {
        display: inline-block;

        cursor: pointer;
        user-select: none;
    }
    &-prev {
        margin-right: 10px;
    }
    &-next {
        margin-left: 10px;
    }
    &-item {
        line-height: 18px;

        min-width: 18px;
        height: 18px;
        margin: auto 6px;
        padding: 2px;

        text-align: center;

        border-radius: 3px;

        a {
            display: flex;

            width: 100%;
            height: 100%;

            transition: none;

            justify-content: center;
            align-items: center;

            &:hover {
                text-decoration: none;
            }
        }
        &:hover {
            opacity: .5;
            color: #fff;
            background-color: #3cd0ac;
        }
        &-active {
            color: #fff;
            background-color: #3cd0ac;
        }
    }
    &-jump-prev,
    &-jump-next {
        cursor: pointer;
        .#{$pagination-prefix-cls}-item-container {
            position: relative;
            .#{$pagination-prefix-cls}-item-link-icon {
                position: absolute;

                width: 100%;

                transition: all .2s;
                text-align: center;
                letter-spacing: -1px;

                opacity: 0;
            }
            .#{$pagination-prefix-cls}-item-ellipsis {
                display: block;

                margin: auto;

                transition: all .2s;
                text-align: center;
                text-indent: .13em;
                letter-spacing: 2px;

                opacity: 1;
            }
        }
        &:focus,
        &:hover {
            .#{$pagination-prefix-cls}-item-link-icon {
                opacity: 1;
            }
            .#{$pagination-prefix-cls}-item-ellipsis {
                opacity: 0;
            }
        }
    }
    &-disabled {
        cursor: not-allowed;

        color: #ccc;
    }
    &-options {
        &-size-changer {
            display: flex;

            margin-right: 20px;

            align-items: center;

            &-label {
                color: #9aa5cc;
            }
            &-select,
            .mat-select {
                width: 65px;
                height: 24px;

                border: 1px solid #bac0c6;
            }
            .mat-select-trigger,
            .mat-select-value {
                line-height: 24px;

                height: 100%;
            }
            .mat-select-value {
                padding: 0 5px;

                text-align: center;
            }
        }
        &-quick-jumper {
            margin-left: 20px;

            white-space: nowrap;
            &-label {
                box-sizing: border-box;
                height: 24px;
                padding: 2px 4px;

                cursor: pointer;
                user-select: none;

                border: 1px solid #bac0c6;
            }
            &-input {
                width: 65px;
                height: 24px;
                padding: 0 10px;

                border: 1px solid #bac0c6;
            }
        }
        &-size-changer-label,
        &-quick-jumper-label {
            margin-left: 5px;
        }
    }
}
