$themeColor: #3cd0ac;
// $themeColor: #5e72e4;

@mixin zy-flex($direction, $justify: flex-start, $align: flex-start)
{
    display: flex;
    flex-direction: #{$direction};

    justify-content: #{$justify};
    align-items: #{$align};
}

@mixin zy-text-ellipse
{
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin zy-screen($min, $max)
{
    @media screen and ( min-width: $min ) and ( max-width: $max )
    {
        @content;
    }
}

@mixin zy-text-no-select
{
    user-select: none;
}

@mixin zy-absolute-position-top-left($top, $left)
{
    position: absolute;
    top: $top;
    left: $left;
}

@mixin zy-placeholder-color($color)
{
    &::-webkit-input-placeholder
    {
        color: $color;
    }
    &::-moz-input-placeholder
    {
        color: $color;
    }
    &::-ms-input-placeholder
    {
        color: $color;
    }
}

@mixin zy-scrollbar-for-chrome
{
    overflow: auto;

    &::-webkit-scrollbar
    {
        width: 5px;
        height: 1px;
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background: #ccc;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
                box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
    }

    &::-webkit-scrollbar-track
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
                box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
    }
}

@mixin absolute-center
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    margin: auto;
}

@mixin absolute-horizontal-center
{
    position: absolute;
    left: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;
}
@mixin absolute-vertical-center
{
    position: absolute;
    top: 0;
    bottom: 0;

    margin-top: auto;
    margin-bottom: auto;
}

@mixin card-base-style {
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 10px 0 rgba(57, 41, 114, .05);
}

@mixin card-icon-base-style {
    line-height: 92px;

    position: absolute;
    top: -14px;
    left: 45px;

    width: 96px;
    height: 92px;

    text-align: center;

    color: #fff;
    border-radius: 4px;
    background-color: #3b4be8;
    box-shadow: 0 6px 9px -1px rgba(184, 190, 231, 1);

    i
    {
        font-size: 34px;
    }
}
