$th-heght: 32px;
$th-lf-border-color: #e5e5e5;
$th-pd-lf: 12px;
$th-pd-rt: 12px;
$td-height: 40px;
$tr-dark-bg-color: #fafafa;
$thead-font-color: #6c7589;
$thead-font-size: 14px;
$thead-font-weight: 400;
$tbody-font-color: #252639;
$tbody-font-size: 14px;
$tbody-font-weight: 400;
$dark-border-color: #bac0c6;
$default-border-color: #dbdde1;
$table-top-height: 40px;
$table-top-mg-bottom: 20px;
