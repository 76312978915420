@font-face {
  font-family: "iconfont"; /* Project id 3677470 */
  src: url('./iconfont.woff2?t=1711437978832') format('woff2'),
       url('./iconfont.woff?t=1711437978832') format('woff'),
       url('./iconfont.ttf?t=1711437978832') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-qklkefu:before {
  content: "\e65a";
}

.icon-qklshouye:before {
  content: "\e643";
}

.icon-qkldaifukuan:before {
  content: "\e63f";
}

.icon-qklxiangmu:before {
  content: "\e606";
}

.icon-qkljiesuan:before {
  content: "\e70f";
}

.icon-qklzhanghao:before {
  content: "\e650";
}

.icon-qklkehu:before {
  content: "\e627";
}

.icon-qklyiyuan:before {
  content: "\e6fd";
}

.icon-qklshenheyuan:before {
  content: "\e6b0";
}

.icon-qkljiesuanguanli:before {
  content: "\e76c";
}

.icon-qkljiluyuan:before {
  content: "\e607";
}

.icon-qklshoushizhe:before {
  content: "\e844";
}

.icon-qklexport:before {
  content: "\e642";
}

.icon-qklchenggong:before {
  content: "\e61e";
}

.icon-qklgongsi:before {
  content: "\e640";
}

.icon-qklshuaxin:before {
  content: "\e61c";
}

.icon-qkltianjiarenyuan:before {
  content: "\e602";
}

.icon-qkltianjia:before {
  content: "\e64f";
}

.icon-qklxiayiye:before {
  content: "\e615";
}

.icon-qklshangyiye:before {
  content: "\e614";
}

.icon-qklguanbi:before {
  content: "\eaf2";
}

.icon-qkluser-filling:before {
  content: "\e68b";
}

.icon-qklweizhi:before {
  content: "\e601";
}

.icon-qkl31dingwei:before {
  content: "\e600";
}

.icon-qklguanfangbanben:before {
  content: "\e603";
}

.icon-qklicon-:before {
  content: "\e707";
}

.icon-qklmima:before {
  content: "\e625";
}

.icon-qklyonghu:before {
  content: "\e60c";
}

.icon-qklhengbanlogo:before {
  content: "\e634";
}

.icon-qklyonghuguanli:before {
  content: "\e70c";
}

.icon-qkldanweizuzhi:before {
  content: "\e66d";
}

.icon-qklchannel:before {
  content: "\e71c";
}

.icon-qklwuliudingdan:before {
  content: "\e7c7";
}

.icon-qklqukuailian:before {
  content: "\e626";
}

.icon-qklqukuai:before {
  content: "\e994";
}

.icon-qklheyue:before {
  content: "\e645";
}

.icon-qklpingtaiguanli:before {
  content: "\e6a0";
}

