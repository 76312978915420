@charset "UTF-8";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
.zy-table {
  overflow: auto;
  width: 100%;
  max-height: 100%;
  border-spacing: 0;
  border-collapse: separate;
  text-align: left;
}
.zy-table thead th,
.zy-table .zy-header-row .zy-header-cell {
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
  min-height: 32px;
  white-space: nowrap;
  color: #6c7589;
  border-top: 1px solid #bac0c6;
  border-bottom: 1px solid #dbdde1;
}
.zy-table thead th:before,
.zy-table .zy-header-row .zy-header-cell:before {
  position: absolute;
  top: 50%;
  left: 0;
  height: 50%;
  content: "";
  transform: translateY(-50%);
  border-left: 1px solid #e5e5e5;
}
.zy-table thead th:nth-child(1):before,
.zy-table .zy-header-row .zy-header-cell:nth-child(1):before {
  left: 10px;
}
.zy-table tbody td,
.zy-table tfoot td,
.zy-table .zy-row .zy-cell,
.zy-table .zy-footer-row .zy-footer-cell {
  font-size: 14px;
  font-weight: 400;
  min-height: 40px;
  color: #252639;
  border-bottom: 1px solid #dbdde1;
}
.zy-table tfoot td,
.zy-table .zy-footer-row .zy-footer-cell {
  border-top: 1px solid #dbdde1;
  border-bottom: 1px solid #dbdde1;
}
.zy-table tbody tr:nth-child(even),
.zy-table .zy-row:nth-child(even) {
  background-color: #fafafa;
}
.zy-table tbody tr:nth-child(even) td,
.zy-table tbody tr:nth-child(even) .zy-cell,
.zy-table .zy-row:nth-child(even) td,
.zy-table .zy-row:nth-child(even) .zy-cell {
  background-color: #fafafa;
}
.zy-table tbody tr:nth-child(odd),
.zy-table .zy-row:nth-child(odd) {
  background-color: #fff;
}
.zy-table tbody tr:nth-child(odd) td,
.zy-table tbody tr:nth-child(odd) .zy-cell,
.zy-table .zy-row:nth-child(odd) td,
.zy-table .zy-row:nth-child(odd) .zy-cell {
  background-color: #fff;
}
.zy-table thead th,
.zy-table tbody td,
.zy-table tfoot td,
.zy-table .zy-header-row .zy-header-cell,
.zy-table .zy-row .zy-cell,
.zy-table .zy-footer-row .zy-footer-cell {
  box-sizing: border-box;
  padding-right: 12px;
  padding-left: 12px;
}
.zy-table thead th:nth-child(1),
.zy-table tbody td:nth-child(1),
.zy-table tfoot td:nth-child(1),
.zy-table .zy-header-row .zy-header-cell:nth-child(1),
.zy-table .zy-row .zy-cell:nth-child(1),
.zy-table .zy-footer-row .zy-footer-cell:nth-child(1) {
  padding-left: 22px;
}
.zy-table thead th:nth-child(1):before,
.zy-table tbody td:nth-child(1):before,
.zy-table tfoot td:nth-child(1):before,
.zy-table .zy-header-row .zy-header-cell:nth-child(1):before,
.zy-table .zy-row .zy-cell:nth-child(1):before,
.zy-table .zy-footer-row .zy-footer-cell:nth-child(1):before {
  left: 10px;
}
.zy-table thead th,
.zy-table tfoot td,
.zy-table .zy-header-row .zy-header-cell,
.zy-table .zy-footer-row .zy-footer-cell {
  background-color: #fff;
}

zy-table {
  display: block;
}

zy-row,
zy-header-row,
zy-footer-row {
  display: flex;
  box-sizing: border-box;
  align-items: stretch;
}

zy-cell,
zy-header-cell,
zy-footer-cell {
  display: flex;
  overflow: hidden;
  flex: 1;
  min-height: inherit;
  word-wrap: break-word;
  word-break: break-all;
  align-items: center;
}

:host(.zy-table-wrapper) {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
:host(.zy-table-wrapper) ::ng-deep .zy-pagination {
  margin-top: 30px;
}
:host(.zy-table-wrapper) .zy-table-top {
  display: flex;
  height: 40px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}
:host(.zy-table-wrapper) .zy-table-top .title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  color: #252639;
}
:host(.zy-table-wrapper) .zy-table-top .search {
  display: flex;
  align-items: center;
}
:host(.zy-table-wrapper) .zy-table-top .search-ipt {
  position: relative;
  display: flex;
  width: 200px;
  height: 40px;
  margin-right: 5px;
  opacity: 0.6;
  border-radius: 4px;
  background: rgba(161, 168, 197, 0.2);
  align-items: center;
}
:host(.zy-table-wrapper) .zy-table-top .search-ipt > .iconfont {
  font-size: 14px;
  position: absolute;
  left: 10px;
  color: #a0a7c4;
}
:host(.zy-table-wrapper) .zy-table-top .search-ipt > input {
  width: 100%;
  height: 100%;
  padding: 0 10px 0 40px;
  color: #252639;
  border: none;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
}
:host(.zy-table-wrapper) .zy-table-top .search-ipt > input::placeholder {
  color: #a0a7c4;
}
:host(.zy-table-wrapper) .zy-table-top .search-btn {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  color: #252639;
  border: none;
  outline: none;
  background-color: transparent;
}
:host(.zy-table-wrapper) .zy-table-top .search-btn-default {
  color: #3cd0ac;
}
:host(.zy-table-wrapper) .zy-table-top .search-point {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #252639;
}
:host(.zy-table-wrapper) .zy-table-container {
  overflow: hidden;
  flex: 1;
  position: relative;
}
:host(.zy-table-wrapper) .zy-table-container-1 {
  overflow: hidden;
  flex: 1;
  position: absolute;
  top: 40px;
  width: 100%;
  height: 100%;
  background: white;
}
:host(.zy-table-wrapper) .zy-table-loading {
  position: absolute;
  z-index: 999;
  top: -40px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: #fff;
}
:host(.zy-table-wrapper) .zy-table-loading:after {
  display: block;
  width: 90px;
  height: 90px;
  content: "";
  background-image: url("../../../../assets/imgs/loading.gif");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
:host(.zy-table-wrapper) .zy-table-empty {
  position: absolute;
  z-index: 999;
  top: -40px;
  right: 0;
  bottom: 10px;
  left: 0;
  background: #fff;
}
:host(.zy-table-wrapper) .zy-table-empty:after {
  display: block;
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
:host(.zy-table-wrapper) .zy-table-empty-text {
  position: absolute;
  right: 0;
  left: 0;
  width: 200px;
  height: 30px;
  margin: 100px auto;
  cursor: default;
  user-select: none;
  text-align: center;
  color: #aaa;
}

.zy-pagination {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  color: #212121;
  justify-content: space-between;
  flex-wrap: wrap;
}
.zy-pagination > div {
  margin-top: 3px;
  margin-bottom: 3px;
}
.zy-pagination ul {
  margin: 0;
  padding: 0;
}
.zy-pagination li {
  list-style: none;
}
.zy-pagination-total {
  display: flex;
  align-items: center;
}
.zy-pagination-total-title {
  color: #9ca5c9;
}
.zy-pagination-total-num {
  color: #3cd0ac;
}
.zy-pagination-main {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.zy-pagination-item, .zy-pagination-prev, .zy-pagination-next, .zy-pagination-jump-prev, .zy-pagination-jump-next {
  display: inline-block;
  cursor: pointer;
  user-select: none;
}
.zy-pagination-prev {
  margin-right: 10px;
}
.zy-pagination-next {
  margin-left: 10px;
}
.zy-pagination-item {
  line-height: 18px;
  min-width: 18px;
  height: 18px;
  margin: auto 6px;
  padding: 2px;
  text-align: center;
  border-radius: 3px;
}
.zy-pagination-item a {
  display: flex;
  width: 100%;
  height: 100%;
  transition: none;
  justify-content: center;
  align-items: center;
}
.zy-pagination-item a:hover {
  text-decoration: none;
}
.zy-pagination-item:hover {
  opacity: 0.5;
  color: #fff;
  background-color: #3cd0ac;
}
.zy-pagination-item-active {
  color: #fff;
  background-color: #3cd0ac;
}
.zy-pagination-jump-prev, .zy-pagination-jump-next {
  cursor: pointer;
}
.zy-pagination-jump-prev .zy-pagination-item-container, .zy-pagination-jump-next .zy-pagination-item-container {
  position: relative;
}
.zy-pagination-jump-prev .zy-pagination-item-container .zy-pagination-item-link-icon, .zy-pagination-jump-next .zy-pagination-item-container .zy-pagination-item-link-icon {
  position: absolute;
  width: 100%;
  transition: all 0.2s;
  text-align: center;
  letter-spacing: -1px;
  opacity: 0;
}
.zy-pagination-jump-prev .zy-pagination-item-container .zy-pagination-item-ellipsis, .zy-pagination-jump-next .zy-pagination-item-container .zy-pagination-item-ellipsis {
  display: block;
  margin: auto;
  transition: all 0.2s;
  text-align: center;
  text-indent: 0.13em;
  letter-spacing: 2px;
  opacity: 1;
}
.zy-pagination-jump-prev:focus .zy-pagination-item-link-icon, .zy-pagination-jump-prev:hover .zy-pagination-item-link-icon, .zy-pagination-jump-next:focus .zy-pagination-item-link-icon, .zy-pagination-jump-next:hover .zy-pagination-item-link-icon {
  opacity: 1;
}
.zy-pagination-jump-prev:focus .zy-pagination-item-ellipsis, .zy-pagination-jump-prev:hover .zy-pagination-item-ellipsis, .zy-pagination-jump-next:focus .zy-pagination-item-ellipsis, .zy-pagination-jump-next:hover .zy-pagination-item-ellipsis {
  opacity: 0;
}
.zy-pagination-disabled {
  cursor: not-allowed;
  color: #ccc;
}
.zy-pagination-options-size-changer {
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.zy-pagination-options-size-changer-label {
  color: #9aa5cc;
}
.zy-pagination-options-size-changer-select,
.zy-pagination-options-size-changer .mat-select {
  width: 65px;
  height: 24px;
  border: 1px solid #bac0c6;
}
.zy-pagination-options-size-changer .mat-select-trigger,
.zy-pagination-options-size-changer .mat-select-value {
  line-height: 24px;
  height: 100%;
}
.zy-pagination-options-size-changer .mat-select-value {
  padding: 0 5px;
  text-align: center;
}
.zy-pagination-options-quick-jumper {
  margin-left: 20px;
  white-space: nowrap;
}
.zy-pagination-options-quick-jumper-label {
  box-sizing: border-box;
  height: 24px;
  padding: 2px 4px;
  cursor: pointer;
  user-select: none;
  border: 1px solid #bac0c6;
}
.zy-pagination-options-quick-jumper-input {
  width: 65px;
  height: 24px;
  padding: 0 10px;
  border: 1px solid #bac0c6;
}
.zy-pagination-options-size-changer-label, .zy-pagination-options-quick-jumper-label {
  margin-left: 5px;
}

html,
body {
  height: 100%;
}

body {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  margin: 0;
  background: #f5f5fa;
}

* {
  margin: 0;
  padding: 0;
}

.navigator-content-main {
  overflow: hidden !important;
  height: calc(100% - 88px);
  margin: 0 30px 20px 30px;
  padding: 0 30px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(57, 41, 114, 0.05);
}
.navigator-content-main.without-padding {
  padding: 0;
  position: relative;
}
.navigator-content-main.list-container {
  box-sizing: border-box;
  padding: 25px 65px 25px;
}

.navigator-content-main-bottom {
  overflow: hidden !important;
  min-height: 100%;
  margin: 0 30px 20px 30px;
  padding: 0 30px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(57, 41, 114, 0.05);
}
.navigator-content-main-bottom.haz-bottons {
  padding-bottom: 150px !important;
}

input.mat-input-element {
  line-height: 1em;
}

.amap-marker-label {
  border: none !important;
  background-color: transparent;
}

.mat-form-field--no-underline::ng-deep .mat-form-field-underline {
  background: none !important;
}
.mat-form-field--no-underline::ng-deep .mat-form-field-underline .mat-form-field-ripple {
  display: none !important;
}

/* 在你的 CSS 文件中 */
.required-form-item {
  position: relative;
}

.required-form-item:after {
  content: "*";
  color: red;
  position: absolute;
  left: -10px;
  /* 根据需要调整位置 */
  top: 2px;
  /* 根据需要调整位置 */
  font-size: 16px;
  /* 根据需要调整大小 */
}

.mat-tooltip {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

.met-datepicker-panel-flex .met-datepicker-panel {
  display: flex !important;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

.filter-partners-modal-panel .mat-dialog-container,
.quick-launch-modal-panel .mat-dialog-container,
.filter-vehicle-modal-panel .mat-dialog-container {
  padding: 0;
}

.amap-geolocation-con {
  z-index: 1000 !important;
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane met-options .met-options {
  overflow: auto;
}
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane met-options .met-options::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane met-options .met-options::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}
.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane met-options .met-options::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

.mat-form-field--no-underline::ng-deep .mat-form-field-underline {
  background: none !important;
}
.mat-form-field--no-underline::ng-deep .mat-form-field-underline .mat-form-field-ripple {
  display: none !important;
}

.mat-primary.mat-flat-button.mat-button-base {
  background-color: #3cd0ac;
  color: #fff;
}