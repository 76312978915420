@import '../../../minx.scss';
@import './variable.scss';

$table-prefix-cls: 'zy-table';
$pagination-prefix-cls: 'zy-table';


.#{$pagination-prefix-cls} {
  overflow: auto;

  width: 100%;
  max-height: 100%;

  border-spacing: 0;
  border-collapse: separate;

  text-align: left;
  thead th,
  .zy-header-row .zy-header-cell {
      font: {
          size: $thead-font-size;
          weight: $thead-font-weight;
      };
      line-height: $th-heght;

      position: relative;

      min-height: $th-heght;

      white-space: nowrap;

      color: $thead-font-color;
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $default-border-color;
      &:before {
          position: absolute;
          top: 50%;
          left: 0;

          height: 50%;

          content: '';
          transform: translateY(-50%);

          border-left: 1px solid $th-lf-border-color;
      }
      &:nth-child(1):before {
          left: 10px;
      }
  }
  tbody td,
  tfoot td,
  .zy-row .zy-cell,
  .zy-footer-row .zy-footer-cell {
      font: {
          size: $tbody-font-size;
          weight: $tbody-font-weight;
      };

      min-height: $td-height;

      color: $tbody-font-color;
      border-bottom: 1px solid $default-border-color;
  }
  tfoot td,
  .zy-footer-row .zy-footer-cell {
      border-top: 1px solid $default-border-color;
      border-bottom: 1px solid $default-border-color;
  }
  tbody tr:nth-child(even),
  .zy-row:nth-child(even) {
      background-color: $tr-dark-bg-color;
      td,
      .zy-cell {
          background-color: $tr-dark-bg-color;
      }
  }
  tbody tr:nth-child(odd),
  .zy-row:nth-child(odd) {
      background-color: #fff;
      td,
      .zy-cell {
          background-color: #fff;
      }
  }
  thead th,
  tbody td,
  tfoot td,
  .zy-header-row .zy-header-cell,
  .zy-row .zy-cell,
  .zy-footer-row .zy-footer-cell {
      box-sizing: border-box;
      padding-right: $th-pd-rt;
      padding-left: $th-pd-lf;
      &:nth-child(1) {
          padding-left: 10px + $th-pd-lf;
          &:before {
              left: 10px;
          }
      }
  }
  thead th,
  tfoot td,
  .zy-header-row .zy-header-cell,
  .zy-footer-row .zy-footer-cell {
      background-color: #fff;
  }
}
zy-table {
  display: block;
}

zy-row,
zy-header-row,
zy-footer-row {
  display: flex;

  box-sizing: border-box;

  align-items: stretch;
}
zy-cell,
zy-header-cell,
zy-footer-cell {
  display: flex;
  overflow: hidden;
  flex: 1;

  min-height: inherit;

  word-wrap: break-word;
  word-break: break-all;

  align-items: center;
}


@mixin absolute-center {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    margin: auto;
}

:host(.#{$table-prefix-cls}-wrapper) {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    ::ng-deep {
        .zy-pagination {
            margin-top: 30px;
        }
    }

    .#{$table-prefix-cls}-top {
        display: flex;

        height: $table-top-height;
        margin-bottom: $table-top-mg-bottom;

        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 18px;
            font-weight: 600;

            margin: 0;

            white-space: nowrap;

            color: rgba(37, 38, 57, 1);
        }

        .search {
            display: flex;

            align-items: center;

            &-ipt {
                position: relative;

                display: flex;

                width: 200px;
                height: 40px;
                margin-right: 5px;

                opacity: .6;
                border-radius: 4px;
                background: rgba(161, 168, 197, .2);

                align-items: center;

                > .iconfont {
                    font-size: 14px;

                    position: absolute;
                    left: 10px;

                    color: #a0a7c4;
                }

                > input {
                    width: 100%;
                    height: 100%;
                    padding: 0 10px 0 40px;

                    color: rgba(37, 38, 57, 1);
                    border: none;
                    border-radius: 4px;
                    outline: none;
                    background-color: transparent;

                    &::placeholder {
                        color: #a0a7c4;
                    }
                }
            }

            &-btn {
                font-size: 14px;
                font-weight: 400;

                cursor: pointer;
                white-space: nowrap;

                color: rgba(37, 38, 57, 1);
                border: none;
                outline: none;
                background-color: transparent;

                &-default {
                    color: #3cd0ac;
                }
            }

            &-point {
                width: 4px;
                height: 4px;

                border-radius: 50%;
                background: #252639;
            }
        }
    }

    .#{$table-prefix-cls}-container {
        overflow: hidden;
        flex: 1;
        position: relative;
    }
    .#{$table-prefix-cls}-container-1 {
        overflow: hidden;
        flex: 1;
        position: absolute;
        top: 40px;
        width: 100%;
        height: 100%;
        background: white;
    }

    .#{$table-prefix-cls}-loading {
        position: absolute;
        z-index: 999;
        top: -40px;
        right: 0;
        bottom: 0;
        left: 0;

        margin: auto;

        background: #fff;

        &:after {
            display: block;

            width: 90px;
            height: 90px;

            content: '';

            background-image: url('../../../../assets/imgs/loading.gif');
            background-repeat: no-repeat;
            background-size: cover;

@include absolute-center();
        }
    }

    .#{$table-prefix-cls}-empty {
        position: absolute;
        z-index: 999;
        top: -40px;
        right: 0;
        bottom: 10px;
        left: 0;

        background: #fff;


        &:after {
            display: block;

            content: '';

// @include retina-sprite($empty-group);
            //background-image: url('../../../../assets/imgs/empty.png');
            background-repeat: no-repeat;
            background-size: cover;

@include absolute-center();
        }

        &-text {
            position: absolute;
            right: 0;
            // bottom: calc(50% - 165px);
            // top: 50px;
            left: 0;

            width: 200px;
            height: 30px;
            margin: 100px auto;

            cursor: default;
            user-select: none;
            text-align: center;

            color: #aaa;
        }
    }
}
